
.info-box{
    display: flex;
    box-shadow: 0px 0px 4px 0 grey;
    margin: 4px 6px;
    /* min-width: 175px;
    width: min-content; */
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.info-box.active{
    background-color: #aee2ae;
}
.info-box-icon.edit{
    width: 31px;
}

.info-box .info-box-icon.img{
    height: 56px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #999;
}

.info-box .image{
    width: 90%;
    height: 90%;
    /* background-image: url(/../assets/img/no_image.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.info-box .info-box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 8px;
    min-width: 90px;
}

.info-box .info-box-icon.edit i{
    font-size: 24px;
    display: none;
}
.info-box:hover .info-box-icon.edit i{
    display: block;
}

.viewFilePhoto{
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.topPage{
    display: flex;
    overflow: auto;
    margin-right: 10px;
}

.scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .scrollbar::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 8px;
    list-style: none;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
    opacity: 1;
}

.form-group.gr_f {
    display: flex;
    align-items: center;
    position: relative;
}

.form-group.gr_f small{
    position: absolute;
    bottom: -3px;
    left: 24%; 
}

.form-group.gr_f > * {
    margin: 0 4px;
}
.center{text-align: center;}
.form-group.gr_f > *:first-child {margin-left: 0;}
.form-group.gr_f > *:last-child {margin-right: 0;}

.LoadFile{
    position: relative;
    border: 1px solid;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoadFile input[type="file"]{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.ic_mesures{
    background-image: url(./assets/icon/mesures.png);
    width: 30px;
    height: 20px;
    display: inline-block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.form-control.error{
    border-color:red;
}

.flex{
    display: flex;
}

.flex > div{
    padding: 0 12px;
}

.flex > div:first-child{padding-left: 0;}
.flex > div:last-child{padding-right: 0;}



.f-jcenter{
    justify-content: center;
}
.f-jbetween{
    justify-content: space-between;
}

.f-acenter{
    align-items: center;
}

.f-col{
    flex-direction: column;
}

.f-row{
    flex-direction: row;
}

.w-5{
    width: 5%;
}
.w-10{
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-25{
    width: 25%;
}
.w-33{
    width: 33%;
}
.w-50{
    width: 50%;
}
.w-60{
    width: 60%;
}
.w-70{
    width: 70%;
}
.w-75{
    width: 75%;
}
.w-80{
    width: 80%;
}
.w-100{
    width: 100%;
}

.p-0{
    padding:0 !important;
}

.flex-acenter{
    display: flex;
    align-items: center;
}

.flexMesure{
    display: flex;
    align-items: center;
}

.flexMesure label{
    margin: 0;
    margin-right: 8px;
}

.LoadFile img{
    width: 100%;
    max-height: 100%;
}

.LoadFile span{
    text-align: center;
}

.gr_f{
    display: flex;
    align-items: center;
}


.gr_f h5{
    margin: 0;
}
.gr_f input[type="checkbox"]{
 margin-left: 10px;
}

.galeryItems{
    display: flex;
    flex-wrap: wrap;
    max-width: 1040px;
    min-height: 150px;
}
.galeryItems > div{
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.galeryItems > div > img{
    background-repeat: no-repeat;
    border: 1px solid;
    width: 250px;
    /* height: 200px; */
    cursor: pointer;
    background-position: center;
    background-size: cover;
    margin: 0 5px 10px;
}

.editImg{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editImg > span{
    cursor: pointer;
    /* display: none; */
}

.editImg:hover > span{
    /* display: block; */
}

.langInput .form-group{
    padding-top: 0;
}

input[type="checkbox"]{
    border: 1px solid gray;
}

.clickLeve{
    cursor: pointer;
}

.selectTopLink{
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #dddddd;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.selectTopLink > div{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 0px 6px;
}

.selectTopLink > div > span{
    cursor: pointer;
    font-size: 15px;
    min-width: 120px;
    border: 1px solid gray;
    padding: 12px 12px;
    margin-bottom: 6px;
    text-align: center;
}

.selectTopLink > div > span.active{
    background-color: #b9e2b9;
}

.selectTopLink > div > i{
    cursor: pointer;
    font-size: 20px;
}

.showTip{
    position: relative;
}

.showTip > span{
    display: none;
    position: absolute;
    top: 25px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 7px 0 #808080c7;
    border-radius: 2px;
    padding: 6px 6px;
    z-index: 10;
}

.showTip:hover > span{
    display: block;
}

.showTip.act_0 > span{
    display: none;
}

input.error{
    border-color: red !important;
}

.flexGroupTitle{
    display: flex;
    align-items: center;
}

.flexGroupLabel{
    text-align: center;
    font-weight: bold;
}
.flexGroupList{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 6px 20px;
    margin: 6px;
}
.flexGroupList > div{
    text-align: center;
}
.flexGroupList > div > span{

}


.table-group{
    width: 100%;
    margin-bottom: 10px;
    border-collapse: inherit;
    border-spacing: 8px 0px;
}
.table-group thead {

}
.table-group thead tr{

}
.table-group thead td {
    font-weight: bold;
    padding: 5px 5px;
}
.table-group tbody {

}
.table-group tbody tr{
    border-bottom: 2px solid gray;
}
.table-group tbody tr:last-child{
    border:0px solid;
}
.table-group tbody td {
    vertical-align: middle;
    min-height: 100px;
    border: 1px solid gray;
    position: relative;
}

.table-group tbody td  hr {
    margin: 0;
    padding: 0;
    border-top: 3px solid #adadad;
    position: absolute;
    bottom: -3px;
    width: 106%;
}

.table-group tbody tr:last-child td  hr {
    border-top: 0px solid #adadad;
}


.table-group tbody td > div {
    /* border-left: 1px solid gray;
    border-right: 1px solid gray; */
    margin: 0 5px;
    padding: 4px 10px;
    min-height: 28px;
    min-width: 262px;
}


.table-group tbody tr:first-child td > div {
    /* border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray; */
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */

}

.table-group tbody tr:first-child td {
    /* border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.table-group tbody tr:last-child td > div {
    /* border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
}


.table-group tbody tr:last-child td {
    /* border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;*/
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; 
}

/* .table-group tbody tr td:first-child > div{
    width: max-content;
    border: 0px solid;
} */

.table-group tbody tr td:first-child{
    width: max-content;
    border: 0px solid;
    border-bottom: 1px solid gray;
}

.table-group tbody tr:last-child td:first-child{
    border-bottom: 0px solid gray;
}

.table-group tbody tr td:first-child > div {
    min-width: inherit;
    width: inherit;
}

.table-group tbody tr td:first-child{

}
.imageUUUpla{

}

.imageUUUpla input[type="file"]{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.flexWidgetPopup{
    display: flex;
    align-items: center;
    /* position: absolute;
    top: 20px;
    right: 158px; */
}

.flexWidgetPopup label{
    margin: 0;
    margin-right: 12px;
}

.scroll{
    width: 100%;
    overflow: auto;
}

.flexLabel{
    display: inline-flex;
    align-items: center;
}

.flexLabel input{
    margin-left: 8px;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    
  }
  
  /* Track */
  .scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px; 
  }
   
  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .scroll::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .flexGroup{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .flexGroup > label{
    width: 113px; 
  }

  .addPricing{
    font-size: 26px;
    line-height: 1;
    height: 0;
    position: relative;
    top: 4px;
    font-weight: 700;
    cursor: pointer;
    margin-left: 5px;
  }

  .tablepricing{
    width: 100%;
    margin: 10px 0;
  }

  .tablepricing td{
      vertical-align: middle;
      padding: 3px 0;
  }

  .tablepricing input{
    width: 125px;
    display: inline-block;
    margin-left: 5px;
  }

  .tablepricing input[readonly]{
    border: 0;
    background: transparent;
    box-shadow: inherit;
    padding: 0;
  }

  .priceStartFrom{
    display: flex;
    width: 100%;
    margin: 20px 0;
    align-items: center;
  }

.priceStartFrom > div{
    width: 20%; 
    padding: 8px;
}

.clickWid{
    border: 1px solid;
    cursor: pointer;
    padding: 4px 4px;
    width: 95px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.headWidget{
    border: 1px solid;
    width: 95px;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.icon-ls{
    background-image: url('./assets/icon/warehouse.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}
.icon-is{
    background-image: url('./assets/icon/in-stock.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}
.icon-cag{
    background-image: url('./assets/icon/shop.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-mrcag{
    background-image: url('./assets/navigation/smartphone.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-or{
    background-image: url('./assets/icon/order.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-pay{
    background-image: url('./assets/icon/credit-card.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-settings{
    background-image: url('./assets/icon/settings.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-customer{
    background-image: url('./assets/icon/customer.png');
    display: inline-block;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
}

.popupTitleRight{
    position: absolute;
    top: 19px;
    right: 20px;
}

.tabPricing {

}

.tabPricing td{
    vertical-align: bottom;
}

.tipPrice{
    position: relative;
}

.tipPrice.btn{
    min-width:100%;
    background-color: #d4d4d4 !important;
}


.tipPrice ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background: #fff;
    top: 21px;
    left: 0;
    box-shadow: 0 0 4px 0 grey;
    display: none;
    z-index: 1000;
}

tr.active td{
    background-color: #d0d0d0 !important;
}

.tipPrice:hover ul{
    display: block;
}

.tipPrice ul li{
    padding: 1px 9px;
}

.groupFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.groupFlex > *{
    margin: 0 12px;
}
.labb{
    width: 120px;
}
.cardIcon{
    border: 1px solid gray;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.cardIcon input[type="file"]{
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    left: 0;
    top:0;
}

.d-flex-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexCenterCol{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.enableCard {
    display: flex;
    align-items: center;
}

.enableCard input{
    margin-left: 8px;
}

.PopupPricing{

}



.PopupPricing > div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* padding: 0; */
}
.PopupPricing .panel {
    min-height: 100%;
}

.PopupPricing .panel .panel-body{
    /* height: calc(100% - 43px);
    min-height: calc(100% - 43px);*/
    /* position: relative;  */
    position: relative;
    padding-bottom: 66px;
    min-height: calc(100vh - 72px);
}

.PopupPricing .panel .panel-body .box-footer{
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.labelCheckbox{
    width: inherit !important;
    display: flex;
    align-items: center;
}

.timesSchema{
    cursor: pointer;
    margin-left: 10px;
    font-size: 25px;
    line-height: 0px;
    font-weight: bold;
    color: #e3503e;
}

.flexaa{
    display: flex;
    align-items: center;
}

.flexth{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexPricing{
    display: flex;
    align-items: flex-end;
}

.flexButtonPricing{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 5px;
}

.currencyGroup{
    /* width: 20%; */
    width: calc(100% / 7);
    margin: 5px;
    text-align: center;
}
.currencyGroup input{
    font-weight: 100;
}

/* Chrome, Safari, Edge, Opera */
.currencyGroup input::-webkit-outer-spin-button,
.currencyGroup input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.currencyGroup input[type=number] {
  -moz-appearance: textfield;
}

.currecnyFlex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tablePacking td{
    vertical-align: top;
}


.labelflex{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;
}

.labelflex input{
    margin-left: 12px;
}
.form-control.PhoneInput > input{
    outline: none;
    border: 0px;
}

input.form-control.no::-webkit-outer-spin-button,
input.form-control.no::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.form-control.no[type=number] {
  -moz-appearance: textfield;
}
/* .working-hours .reset button:active{
    outline: 0;
    border: 0px solid;
} */

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }


.flexNoImage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 25px;
}


.ProductCategoryList{
    display: flex;
    flex-wrap: wrap;
}
.ProductCategory{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px 8px;
}

.ProductCategory b{
    color: red;
    cursor: pointer;
    margin-left: 3px;
    font-size: 18px;
}

.productCategory{
    list-style-type: none;
    padding: 0;
    margin: 0;

    overflow: auto;
    max-height: 400px;
}

.productCategory li{
    display: flex;
    /* justify-content: space-between; */
    font-size: 16px;
    padding: 3px 0;
}

.productCategory li span:last-child{
    margin-left: 8px;
}

.viewLoader{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff82;
}

.viewLoader > div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot-loader{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #03A9F4;
    position: relative;
    -webkit-animation: 1.2s scaleDown ease-in-out infinite ;
    animation: 1.2s scaleDown ease-in-out infinite ;
}

.dot-loader:nth-child(2){
    margin: 0 15px;
    -webkit-animation: 1.2s scaleDown ease-in-out infinite .15555s;
    animation: 1.2s scaleDown ease-in-out infinite .15555s;
}

.dot-loader:nth-child(3){
    -webkit-animation: 1.2s scaleDown ease-in-out infinite .300000s;
    animation: 1.2s scaleDown ease-in-out infinite .300000s;
}

@-webkit-keyframes scaleDown{
    0%, 80%, 100%{
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    40%{
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@keyframes scaleDown{
    0%, 80%, 100%{
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    40%{
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

.ReacordList{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ReacordList li{
    display: flex;
    align-items: center;
}

.ReacordList li span.label{
    margin-right: 8px;
    font-size: 12px;
    margin-bottom: 3px;
    /* cursor: pointer; */
}

.ReacordList li span.label.label-warning{
    cursor: pointer;
}

.ReacordList li span{
    margin-right: 8px;
    cursor: pointer;
}

.table.DragAndDrop{
    border-spacing: 2px 6px;
    border-collapse: inherit;
}

.table.DragAndDrop tbody td{
    border-top: 1px dashed gray;
    border-bottom: 1px dashed gray;
}

.table.DragAndDrop tbody tr td:first-child{
    border-left: 1px dashed gray;
}

.table.DragAndDrop tbody tr td:last-child{
    border-right: 1px dashed gray;
}

.pb-4, .py-4 {
    padding-bottom: 1.5rem !important;
}
.second-product-hdngs {
    font-size: 20px;
}
.font-weight-bolder {
    font-weight: bolder !important;
}

.center-margin {
    margin-left: 50%;
}
.text-center {
    text-align: center !important;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}
.pl-3, .px-3 {
    padding-left: 1rem !important;
}
.pr-3, .px-3 {
    padding-right: 1rem !important;
}

.circle-second-product {
    width: 73px;
    height: 73px;
    background: #76ABC4;
    border-radius: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.font-size-custom {
    font-size: 22px;
}
.text-white {
    margin: 0;
    color: #fff !important;
}
.pt-4, .py-4 {
    padding-top: 1.5rem !important;
}
.pl-1, .px-1 {
    padding-left: .25rem !important;
}
.total-fancy-div {
    border: 1px solid lightgrey;
    border-radius: 240px;
    width: 125px;
    height: 125px;
}

.fancy-pic {
    /* margin-top: -34px;
    margin-left: 37px;
    padding-bottom: 17px;
    vertical-align: middle;
    border-style: none; */

    width: 60px;
    height: 60px;
}

.fancy-pic-body{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -5px;
    min-height: 28px;
    background-color: #fff;
    width: 90px;
    height: 60px;
    margin: 0 auto;
}

.fancy-img-inner-text-div {
    text-align: center;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* width: 100%;
    height: 60%; */
    font-size: 16px;
}

.color-mehroon {
    color: #915674;
}

.flexCricle{
    display: flex;
    align-items: center;
}

.flexCricle > span{
    width: 10px;
    height: 10px;
    border-radius: 100px;
    border: 1px solid #915674;
    margin: 0 2px;
}

.flexCricle > span.active{
    background-color: #915674;
}

.progressRate{
    background-color: #F0F0F0;
    height: 12px;
    width: 160px;
    margin: 0 12px;
}

.progressRate > div{
    background-color: #915674;
    height: 100%;
    width: 0;
}

.tableRate td{
    /* padding-bottom: 10px; */
}

.groupTitle{
    display: flex;
    align-items: center;
}

.groupTitle img{
    margin-right: 12px;
}

.groupbody{

}

.groupbody small{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 11px;
line-height: 15px;
/* identical to box height */
color: #4A4A4A;
}

.progressGroup{
    background: #C4C4C4;
    border-radius: 30px;
    height: 12px;
    width: 100%;
    margin: 5px 0;
    position: relative;
}

.progressGroup > div{
    background: #915674;
    border-radius: 30px;
    height: 100%;
    width: 0%;
    max-width: 100%;
}

.progressGroup > span{
    width: 6px;
    height: 100%;
    display: inline-block;
    position: absolute;
    top:0;
    background-color: #fff;
    margin-left: -3px;
}

.progressGroup > span:nth-child(2){
    left: 20%;
}
.progressGroup > span:nth-child(3){
    left: 40%; 
}
.progressGroup > span:nth-child(4){
    left: 60%; 
}

.progressGroup > span:nth-child(5){
    left: 80%;
}

.attrCli{
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: gray;
    /* border: 1px solid gray; */
    cursor: pointer;
}

.dragAndDropImage{

}

.iconTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border:1px solid gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.iconTitle input[type="file"]{
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.iconLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 100px;
    /* border-radius: 100%; */
    border:1px solid gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.iconLogo span{
    position:absolute;
}
.iconLogo input[type="file"]{
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.colorStyle{
    width: 40px;
    height: 40px;
    border:1px solid gray
}

.full {
    width: auto !important;
}

.iconImg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border:1px solid gray
}

.pointer{
    cursor: pointer;
}

.demo-editor{
    border: 1px solid gray;
    min-height: 200px;
}

.demo-editor .rdw-center-aligned-block{
    min-height: 200px; 
}

.order{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
  }
  
  .order-header{
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
  }
  
  .order-body{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  
  .f-weight-5{
    font-weight: 600;
  }
  
  .order-img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 1px solid gray;
    object-fit: cover;
  }

  .mr-3, .mx-3 {
    margin-right: 1rem !important;
}
.statusOrder {
    color: #fff;
    background-color: coral;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 3px;
}


  .infoStatus{
    color: gray;
  }
  .infoStatus span{
    font-weight: 500;
    color: #000;
  }

  input[type="radio"]{
    -webkit-appearance: radio;
    border-color: #9a9a9a !important;
  }

  .rounded-modal-div{
    display: block;
    width: 100%;
    height: 36px;
    padding: 8px 13px;
    font-size: 13px;
    line-height: 1.428571429;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .vendorUpload{
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border: 1px dashed gray;
    border-radius: 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
  }

  .vendorUpload > input{
    display: none;

  }

  .divss {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;

    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 8px 13px;
    font-size: 13px;
    line-height: 1.428571429;
    border-radius: 3px;
    -webkit-user-select: none;
    user-select: none;

    color: #666;
    background-color: #fff;
    border-color: #ddd;
}

.imgCrop1 .ant-upload.ant-upload-select-picture-card{
    width: 208px !important;
    height: 208px !important;
    min-height: 208px !important;
    background-color:inherit !important;
    /* border: 0px solid !important; */
    margin-right: 0;
}
  
.imgCrop1 .ant-upload-list-picture-card-container{
    width: 208px !important;
    height: 208px !important;
    margin: 0 !important;
}
  
.imgCrop1 .ant-upload-list-picture-card-container > div{
    border: 1px solid #d9d9d9;
}
  
.imgCrop1 .ant-upload-list-picture-card-container > div > div:nth-child(2) {
   width: 0 !important;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    opacity: 1 !important;
}


.sliderTemplate{
    margin: 14px 20px;
}
.slick-prev:before, .slick-next:before{
    color: #666 !important;
}

.sliderTemplate .listImg{
    position: relative;
    padding: 3px 6px;

}

.sliderTemplate .listImg img{
    width: 200px;
    height: 140px;
    object-fit: cover;
}

.sliderTemplate .listImg .imgHover{
    position: absolute;
    top: 3px;
    left: 6px;
    width: 95%;
    height: 96%;
    background-color: #00000078;

    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sliderTemplate .listImg:hover .imgHover{
    display: flex;
}

.sliderTemplate .listImg .imgHover > span{
    color: red;
    position: absolute;
    top: -9px;
    right: 7px;
    cursor: pointer;
    font-size: 35px;
}

.sliderTemplate .listImg .imgHover .fa{
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.addlang{
    background: #40a9ff;
    color: #fff;
    width: 110px;
    height: 80px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.clickHover tbody tr:hover{
    background-color: #40a9ff;
    cursor: pointer;
}

.tableLoyalty tr td{
    padding-bottom: 10px !important;
}

#menu li a.parent:after{
    margin-left: 12px;
}

.editLoyalty {
    color: #1e91cf;
    font-size: 15px;
    margin-left: 6px;
    cursor: pointer;
}

.error{
    border-color: red;
}

.categories{
    display: flex;
    flex-wrap: wrap;
}

.categories .categories-list{
    background-color: #fff;
    padding: 16px 14px;
    width: 313px;
    margin-right: 40px;
    margin-bottom: 14px;
}

.categories .categories-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categories .categories-head b > span{
    display:inline-block;
    width: 7px;
    height: 7px;
    background: #DCDCDC;
    border-radius: 100%;
    margin-right: 6px;
}

.categories .pen{
    width: 25px;
    height: 25px;
    background: #c5c5c5;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.categories .pen:hover{
    background: #4572C2;
    color: #fff;
}

.categories .remove{
    width: 25px;
    height: 25px;
    background: #c5c5c5;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
}

.categories .remove:hover{
    background: #e44d33;
    color: #fff;
}

.categories  .total{
    margin-top: 34px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

}

.categories .categories-body{
    display: flex;
    flex-wrap: wrap;
}

.categories .categories-body > span{
    background: #DCDCDC;
    border-radius: 30px;
    padding: 8px 16px;
    margin-right: 8px;
    margin-bottom: 10px;
    cursor: pointer;
}

.categories .categories-body > span.add{
    cursor: pointer;
    background-color: #4572C2;
    color: #fff;
}

.categories .categories-cc{
    width: 45px;
    height: 20px;
    background: #4572C2;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
}

.categories .categories-cc > span{
    display:inline-block;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    background: #DCDCDC;
}

.imgView{
    width: 150px;
    height: 100px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.imgView > img{
    width: 100%;
    height: 100%;
    object-fit: contain;

}

i.hiiroad{
    background-image: url(./assets/img/hiiroad.png);
    display: inline-block;
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    margin-right: 3px;
  }

  .imageVendor{
    border: 1px solid gray;
    width: 460px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .imageVendor input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .imageVendor img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .comentTopAuthor{
    display: flex;
    align-items: center;
  }
  .img{
    background: #f5f5f5;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    margin-right: 14px;
  }

  .product_reviews{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product_reviews > span{
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #67687B;
    margin-left: 8px;
  }
  
  .product_reviews > div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product_reviews > div i{
    margin: 0 1.5px;
    color: var(--purple-1);
  }
  .comentFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}