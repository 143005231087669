.demo body {
  margin: 0;
  background-color: #f3f3f3 !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* Remove the navbar's default margin-bottom and rounded borders */ 
.demo .navbar {
  border-radius: 0px;
  margin-bottom: 0px;
}

/* .navbar-inverse {
  background-color: #1b365b;
  border: none;
} */

/* .navbar-brand {
  padding: 5px 15px;
}

.navbar-inverse .navbar-toggle {
  border-color: #fff;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
  background-color: #254a7c;
} */

/* Set height of the grid so .sidenav can be 100% (adjust as needed) */
/* .row.content {height: 450px}

/* Set gray background color and 100% height */
/* .sidenav {
  padding-top: 20px;
  background-color: #f1f1f1;
  height: 100%;
} */

/* Set black background color, white text and some padding */
/* footer {
  background-color: #555;
  color: white;
  padding: 15px;
} */

/* On small screens, set height to 'auto' for sidenav and grid */
@media screen and (max-width: 767px) {
  .sidenav {
    height: auto;
    padding: 15px;
  }
  .row.content {height:auto;} 
}

.demo  .tab-product button{
  color: #000;
}

.demo  .tab-product button.active{
  font-weight: bold;
  background-color: transparent !important;
  border: 0px solid !important;
  border-bottom: 2px solid !important;
}

.demo  .productFileUpload{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  flex-direction: column;
  padding: 8px 20px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  font-weight: normal;
}

.demo  .productFileUpload p{
  padding: 0;
  margin: 0;
}

.demo  .productFileUpload label{
  /* cursor: pointer;
  color: #5093dc; */
}
.demo  .productFileUpload input{
  display: none;
}

.demo  .imgCrop1 .ant-upload.ant-upload-select-picture-card{
  width: 208px !important;
  height: 208px !important;
  min-height: 208px !important;
  background-color:inherit !important;
  border: 0px solid !important;
  margin-right: 0;
}

.demo  .imgCrop1 .ant-upload-list-picture-card-container{
  width: 208px !important;
  height: 208px !important;
  margin: 0 !important;
}

.demo .imgCrop1 .ant-upload-list-picture-card-container > div,
/* .demo .imgCrop3 .ant-upload-list-picture-card-container > div, */
/* .demo .imgCrop4 .ant-upload-list-picture-card-container > div, */
.demo .imgCrop2 .ant-upload-list-picture-card-container > div{
  border: 1px solid #d9d9d9;
}

.demo  .imgCrop1 .ant-upload-list-picture-card-container > div > div:nth-child(2),
.demo  .imgCrop2 .ant-upload-list-picture-card-container > div > div:nth-child(2) {
 width: 0 !important;
}

.demo .imgCrop2 .ant-upload.ant-upload-select-picture-card{
  width: 180px !important;
  height: 100px !important;

  /* height: auto  !important; */
  background-color:inherit !important;
  border: 0px solid !important;
}

.demo .imgCrop2 .ant-upload-list-picture-card-container{
  width: 180px !important;
  height: 100px !important;
  /* margin: 0 !important; */
}

.demo .imgCrop2 .ant-upload-list-picture-card-container img{
  object-fit: cover !important;
}

.demo .imgCrop3 .ant-upload.ant-upload-select-picture-card{
  width: 100px !important;
  height: 100px !important;

  /* height: auto  !important; */
  background-color:inherit !important;
  border: 0px solid !important;
}

.demo .imgCrop3 .ant-upload-list-picture-card-container{
  width: 100px !important;
  height: 100px !important;
  /* margin: 0 !important; */
}

.demo .imgCrop3 .ant-upload-list-picture-card-container img{
  object-fit: cover !important;
}

.demo .imgCrop4 .ant-upload.ant-upload-select-picture-card{
  width: 100px !important;
  height: 204px !important;

  /* height: auto  !important; */
  background-color:inherit !important;
  border: 0px solid !important;
}

.demo .imgCrop4 .ant-upload-list-picture-card-container{
  width: 100px !important;
  height: 204px !important;
  /* margin: 0 !important; */
}

.demo .imgCrop4 .ant-upload-list-picture-card-container img{
  object-fit: cover !important;
}

.demo .imgCrop5 .ant-upload.ant-upload-select-picture-card{
  width: 100px !important;
  height: 100px !important;

  /* height: auto  !important; */
  background-color:inherit !important;
  border: 0px solid !important;
}

.demo .imgCrop5 .ant-upload-list-picture-card-container{
  width: 100px !important;
  height: 100px !important;
  /* margin: 0 !important; */
}

.demo .imgCrop5 .ant-upload-list-picture-card-container img{
  object-fit: cover !important;
}

.demo .imgCrop2 .ant-upload-list-picture-card .ant-upload-list-item-error,
.demo .imgCrop3 .ant-upload-list-picture-card .ant-upload-list-item-error,
.demo .imgCrop5 .ant-upload-list-picture-card .ant-upload-list-item-error,
  .demo .imgCrop4 .ant-upload-list-picture-card .ant-upload-list-item-error{
  border-color: #d9d9d9 !important;
}

.demo .gr-radio{
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  margin: 0;
  margin-right: 12px;
}

.demo .gr-radio > input[type="radio"]{
  border-color: #777;
}