.Popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

}

.PopupBody{
    /* background: #FFFFFF; */
    border-radius: 10px;

    min-height: 160px;
    width: 520px;
    min-width: 520px;
    position: relative;
    padding: 15px;
}

.close{
    position: absolute;
    top: 3px;
    right: 12px;
    font-size: 26px;
    cursor: pointer;
}
.close:hover{
    color:#e3503e;
}

@media screen and (max-width: 767px) {
    .Popup{
        display: inline;
        padding: 10px;
        /* height: auto;
        min-height: 100%; */
    }
    .PopupBody{
        width: 100% !important;
        padding-top: 1px;
    }
}

